import { Video } from '@signalwire/js'
import {
  checkVersion,
  refreshJWTRequest,
  roomListActions,
  setScopesRequest,
  uiActions,
} from '../redux/features/'
import {
  globalLoaderBeginAction,
  globalLoaderEndAction,
  purgeAction,
} from '../redux/actions'
import { SocketStatus } from '../constants'
import { toastError } from './relay'
import { Participant } from '../redux/interfaces'

interface CreateClientParams {
  host: string
  token: string
  dispatchAction: (action: any) => void
}

// Re-sub every 10 minutes
const SUBSCRIBE_INTERVAL = 10 * 60 * 1000

export const createClient = ({
  host,
  token,
  dispatchAction,
}: CreateClientParams) => {
  let _subscribeOnConnected = false
  let jwtRefreshAttempts = 0
  let _connectPromise : Promise<string> | undefined

  const client = Video.createClient({
    host,
    token,
    _onRefreshToken: () => {
      dispatchAction(refreshJWTRequest())
    },
    logLevel: 'debug',
  })

  // @ts-expect-error
  window.__sdkClient = client

  client.on('session.connected', () => {
    console.debug('CM Connected')
    // if (clientWasConnected) {
    dispatchAction(checkVersion())
    // }
    jwtRefreshAttempts = 0

    dispatchAction(uiActions.setSocketStatus(SocketStatus.Ready))

    if (_subscribeOnConnected) {
      _subscribe().catch((error) => {
        console.error('Subscribe Error', error)
      })
    }
    _subscribeOnConnected = true
  })

  client.on('session.reconnecting', () => {
    console.debug('CM Reconnecting')
    dispatchAction(uiActions.setSocketStatus(SocketStatus.Closed))
  })

  client.on('session.disconnected', () => {
    console.debug('CM Disconnected')
    dispatchAction(uiActions.setSocketStatus(SocketStatus.Closed))
  })

  // @ts-expect-error
  client.videoManager.on('rooms.subscribed', (params: any) => {
    console.debug('CM rooms.subscribed', params.rooms.length, 'rooms')
    dispatchAction(roomListActions.bulkInsert(params))
    dispatchAction(globalLoaderEndAction())
  })

  // @ts-expect-error
  client.videoManager.on('room.added', ({ room }) => {
    // console.debug('CM room.added', room)
    dispatchAction(roomListActions.createOrUpdate(room))
  })

  // @ts-expect-error
  client.videoManager.on('room.updated', ({ room }) => {
    // console.debug('CM room.updated', room)
    dispatchAction(roomListActions.createOrUpdate(room))
  })

  // @ts-expect-error
  client.videoManager.on('room.deleted', ({ room }) => {
    // console.debug('CM room.deleted', room)
    dispatchAction(roomListActions.destroyById(room.id))
  })

  let _subscribeTimeout: ReturnType<typeof setTimeout>
  const _subscribe = async () => {
    console.debug('CM send subscribe')
    try {
      // FIXME: change in the SDK
      // @ts-expect-error
      client.videoManager._latestExecuteParams = null
      // @ts-expect-error
      await client.videoManager.subscribe()

      clearTimeout(_subscribeTimeout)
      _subscribeTimeout = setTimeout(() => {
        _subscribe()
      }, SUBSCRIBE_INTERVAL)
    } catch (error) {
      console.error('CM Subscribe Error', error)
      toastError('Error loading the room list')
    }
  }

  const _connect = async () => {
    dispatchAction(globalLoaderBeginAction())
    dispatchAction(uiActions.setSocketStatus(SocketStatus.Connecting))
    let _connectRevolved:(value: string) => void | undefined
    try {
      console.debug('CM Connect')
      
      _connectPromise = new Promise((resolve) => {
        _connectRevolved = resolve
      })
      await client.connect()
      // @ts-expect-error
      _connectRevolved?.('done')

      dispatchAction(setScopesRequest())
      await _subscribe()
    } catch (error) {
      // @ts-expect-error
      _connectRevolved?.('error')
      console.error('CM Connect Error', error)
      // After 3 attempts, purge the state
      const action =
        jwtRefreshAttempts > 3 ? purgeAction() : refreshJWTRequest()
      console.error(
        'Attempt to restore the session',
        jwtRefreshAttempts,
        action
      )
      dispatchAction(action)
      jwtRefreshAttempts += 1
    }
  }

  return {
    connect: _connect,
    subscribe: _subscribe,
    disconnect: async () => {
      console.debug('CM Disconnect')
      clearTimeout(_subscribeTimeout)
      await client.disconnect()
    },
    reauthenticate: async (token: string) => {
      console.debug('CM Reauthenticate')

      // wait the async connect to complete before trying to reauthenticate
      if(_connectPromise !== undefined) {
        console.debug('CM waiting connect complete...')
        
        if((await _connectPromise) === 'error') return
        console.debug('CM connect completed')
        _connectPromise = undefined
      }

      dispatchAction(uiActions.setSocketStatus(SocketStatus.Connecting))
      // @ts-expect-error
      await client.reauthenticate(token)
    },
    getRoomMembers: async ({ roomId }: { roomId: string }) => {
      console.debug('CM members.get')
      try {
        // TODO: move it in the SDK and expose a method
        const rpcRequest = {
          method: 'video-manager.members.get',
          params: { room_id: roomId },
        }
        // @ts-expect-error
        const response = await client.execute(rpcRequest)
        const members: Participant[] = response.members.map((member: any) => {
          return {
            id: member.id,
            // callId: call ? call.id : undefined,
            roomId: member.room_id,
            // TODO: Used only in-call
            // roomZoneId: null,
            // cantinaUserId: null, // variables?.cantina_user_id,
            // cantinaUserType: null, // variables?.cantina_user_type,
            name: member.name,
            number: member?.email,
            email: member?.email,
            company: member?.company,
            muted: Boolean(member?.audio_muted),
            // talking: false,
            deaf: Boolean(member?.deaf),
            vMuted: Boolean(member?.video_muted),
            // moderator: false,
            // temporaryModerator: false,
            // handRaised: Boolean(video?.handRaised),
            // lowBitrateMode: connectionState.lowBitrateMode || false,
            // hasBanner: !video.fullScreen || false,
            // hasDenoise: audio.blockingNoise || false,
            // performer: variables.performer || false,
            // localVideoCanvas: variables.localVideoCanvas || false,
            // noLocalVideo: variables.noLocalVideo || false,
            // roomUuid: variables.conferenceUUID || null,
            // roomMd5: variables.conferenceMD5 || null,
            // roomName,
            // hasCustomBanner: variables.cantina_name && variables.cantina_name !== '_default_',
            // volume: audio.volumeOutLevel || 0,
            // gain: audio.volumeInLevel || 0,
            // energy: audio.energyLevel || 0,
            // connectionQuality: connectionState.quality || 0, // Network indication
            // canShare: variables.canShare || false, // Can share or not (not used i think)
            isScreenShareLeg: member.type === 'screen', // This user is a screenShare call
            isSecondSourceLeg: member.type === 'device', // This user is a secondSource call
            // reservationId: video.reservationID || null, // Current reservationId on the canvas (presenter/singer etc)
            // autoOverlay: Boolean(video?.autoOverlay), // Render your local video over the MCU
            // mcuLayerId: video.videoLayerID,
            // motionQuality: video?.motionQuality || 0,
            // hasFloor: Boolean(video?.floor),
            // echoCancellation: Boolean(audio.echoCancellation),
            // noiseSuppression: Boolean(audio.noiseSuppression),
            // autoGainControl: Boolean(audio.autoGainControl),
            // studioAudio: Boolean(audio.studioAudio),
          }
        })
        dispatchAction(roomListActions.bootstrapMembers({ roomId, members }))
      } catch (error) {
        toastError('Error loading participant data')
        roomListActions.bootstrapMembers({ roomId, members: [] })
      }
    },
  }
}
